.table {
  border-spacing: 0;
}
.tr:last-child .td {
  border-bottom: 0;
}
.th:last-child,
.td:last-child {
  border-right: 0;
}
.resizer {
  display: inline-block;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  border-left: 1px solid hsl(0, 0%, 100%, 0.5);
}
.resizer.isResizing {
  background: hsl(216, 12%, 84%, 0.582);
}
.h-60vh {
  max-height: 60vh;
}
.tr:nth-child(even) {
  background-color: hsl(210, 20%, 98%);
}
