@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 175px;
  background-color: hsl(0, 0%, 100%);
}
#spinner {
  min-width: 30px;
  min-height: 30px;
  border: 5px solid hsl(177, 66%, 41%);
  border-right: 5px solid hsl(0, 0%, 80%);
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}
